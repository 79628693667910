import React from 'react'
// import respdf from './Resume.pdf'
// import { Document, Page, pdfjs } from 'react-pdf'
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function Resume() {
  return (
    <div className='appRes'>
      <div className='resBox'>
        <p>My most up to date resume:</p>
        <a
          className='resButton'
          href='https://drive.google.com/file/d/1reL0u0E7a8m5Ez3D9oXiG2D4j3jxpfUY/view?usp=sharing'
        >
          Download as Pdf
        </a>
        <a
          className='resButton'
          href='https://docs.google.com/document/d/1ist6A9U-2O1CU4m0HurfTRalRnxLNluY/edit?usp=sharing&ouid=118131661615488281094&rtpof=true&sd=true'
        >
          Download as DocX
        </a>
      </div>
    </div>
  )
}

export default Resume
