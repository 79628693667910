import React from 'react'
import { useState } from 'react'

function ImageBox ({title, text, src, alt, size}) {
  const [clicked, setClicked] = useState(false)

  const par = (
    <div>
      <h1>{title}</h1>
      <p>{text}</p>
    </div>
  )

  const img = (
    <img
      src={src}
      alt={alt}
    ></img>
  )
  let divClasses = `imagebox + size_${size}`

  return (
    <div className={divClasses} onClick={() => setClicked(!clicked)}>
      {clicked ? par : img}
    </div>
  )
}

export default ImageBox
