import React, {useState} from 'react'
import TicTac from './TicTac.js'
import RulesPanel from './RulesPanel.js'
import Constants from "./uttt_constants.js"
const {NAUGHT, CROSS, EMPTY, EMPTY_BOARD} = Constants
//start / empty ult board is array with unique empty boards.
const EMPTY_ULT_BOARD = [[...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD], [...EMPTY_BOARD]]
const EMPTY_ULT_DISPLAY = [...EMPTY_BOARD]
//Winning indexes (indexed by 1 based arrays)
const possibleWinsOneIndexed = [
  [2, 5, 8],
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [1, 5, 9],
  [3, 5, 7],
  [1, 4, 7],
  [3, 6, 9]
]
const possibleWins = possibleWinsOneIndexed.map(winArray => {
  return winArray.map(oneIndexed => oneIndexed - 1)
})


/* Ultimate Tic Tac Toe */
function UltTicTac() {
  const [ultBoard, setUltBoard] = useState(EMPTY_ULT_BOARD)
  const [naughtsTurn, setNaughtsTurn] = useState(false)
  const toggleTurn = () => {
    setNaughtsTurn(naughtsTurn => !naughtsTurn)
  }
  const currentPlayer = naughtsTurn ? NAUGHT : CROSS
  const [currBoard, setCurrBoard] = useState(-1)
  const [ultBoardWins, setUltBoardWins] = useState(EMPTY_ULT_DISPLAY)
  const [darkMode, setDarkMode] = useState(false)
  const toggleDarkMode = () => {
    setDarkMode(darkMode => !darkMode)
  }
  const [stackBoards, setStackBoards] = useState(true)
  const toggleStackBoards = () => {
      setStackBoards(stackBoards => !stackBoards)
  }
  const [isDisplayBoard, setIsDisplayBoard] = useState(false)
  const toggleDisplayBoard = () => {
      setIsDisplayBoard(isDisplayBoard => !isDisplayBoard)
  }

  const [wonBoardsPlayable, setwonBoardsPlayable] = useState(false)
  const toggleWonBoardsPlayable= () => {
    setwonBoardsPlayable(wonBoardsPlayable => !wonBoardsPlayable)
  }
  //checks if a board is full
  function boardIsFull(megaIndex) {
    return ultBoard[megaIndex].every(square => square !== EMPTY)
  }

  function checkWinMajor(megaIndex) {
    possibleWins.forEach(win =>{
      if (win.includes(megaIndex)) {
        debugger
        const [indexA, indexB, indexC] = win
        const playerA = ultBoardWins[indexA]
        const playerB = ultBoardWins[indexB]
        const playerC = ultBoardWins[indexC]

        const isWinner =  (playerA === playerB) &&
                          (playerB === playerC) &&
                          (playerA !== EMPTY)
        
        if (isWinner) {
          console.log("Winner Winner!")
        }
      }
    })
  }
  // Scans a regular board for a winner.
  //updates ultBoardWins with the winner provided there isn't already one.
  //uses brute force algorithm to check win (not efficient)
  function checkWin(megaIndex) {
    if (ultBoardWins[megaIndex] === EMPTY) {
      //This board has yet to declare a winner let's check it.
      

      possibleWins.forEach(win => {
        const [indexA, indexB, indexC] = win
        const playerA = ultBoard[megaIndex][indexA]
        const playerB = ultBoard[megaIndex][indexB]
        const playerC = ultBoard[megaIndex][indexC]

        const isWinner =  (playerA === playerB) &&
                          (playerB === playerC) &&
                          (playerA !== EMPTY)
        if (isWinner) {
          const newUltBoardWins = [...ultBoardWins]
          newUltBoardWins[megaIndex] = playerA
          setUltBoardWins(newUltBoardWins)
          
          return playerA
        } 
      })
    }
    return EMPTY
  }

  function makeMove (index, megaIndex) {
    //is this a valid move?
    //Is the selected square empty?
    const isEmpty = ultBoard[megaIndex][index] === EMPTY
    //Are we in the correct Board?
    let correctBoard = currBoard === -1 | currBoard === megaIndex
    if (!wonBoardsPlayable) {
      correctBoard = ultBoardWins[megaIndex] === EMPTY
    }
    if (isEmpty & correctBoard){
      const newUltBoard = [...ultBoard]
      newUltBoard[megaIndex][index] = currentPlayer
      setUltBoard(newUltBoard)
      checkWin(megaIndex)
      toggleTurn()
      if(boardIsFull(index)){
        console.log("Board is full")
        setCurrBoard(-1);
      } else {
        setCurrBoard(index)
      }
    } else {
      console.log("Invalid Move:")
      if(!isEmpty) console.log("Square is not empty")
      if(!correctBoard) console.log("Incorrect Mini Board")
    }
  }

  const renderSmallBoard = (i) => {
    let active = i === currBoard | currBoard === -1
    // if we can't play in won boards
    if(!wonBoardsPlayable) {
      // if either we have been sent to a full board, or the board has already been won
      if (currBoard === -1 | (ultBoardWins[currBoard] !== EMPTY)) {
        //if this board is won, or full, we can't play here
        if (ultBoardWins[i] !== EMPTY && !boardIsFull(i)) {
          active = false
        } else {
          active = true
        }
      }
    }
    
    return (
    <TicTac 
      board={ultBoard[i]}
      megaIndex={i}
      makeMove={makeMove} 
      toggleTurn={toggleTurn}
      isActive={active}
      currentPlayer={currentPlayer}
      currentWinner={ultBoardWins[i]}
      wonBoardsPlayable={wonBoardsPlayable}
    />)
    
  }
  let darkStr = ""
  if (darkMode) {
    darkStr = " dark-mode"
  }

  let displayBoard = null
  displayBoard = 
  <div className='display-board'>
    <TicTac
      board={ultBoardWins}
      megaIndex={-1}
      makeMove={console.log} 
      toggleTurn={console.log}
      isActive={false}
      currentPlayer={EMPTY}
      currentWinner={EMPTY}
    />
  </div>
  return (
    <div className={'game-container flex-v' + darkStr}>
      <div className={stackBoards? "flex-v" : "flex-h"}>
        <div className='flex-v'>
          <RulesPanel 
            darkMode={darkMode} 
            toggleDarkMode={toggleDarkMode}
            stack={stackBoards}
            toggleStack={toggleStackBoards}
            displayBoard={isDisplayBoard}
            toggleDisplayBoard={toggleDisplayBoard}
            winningBoardsPlayable={wonBoardsPlayable}
            toggleWinningBoardsPlayable={toggleWonBoardsPlayable}
            />
          <div className='ult-board'>
            <div className="ult-board-row">
              {renderSmallBoard(0)}
              {renderSmallBoard(1)}
              {renderSmallBoard(2)}
            </div>
            <div className="ult-board-row low-row">
              {renderSmallBoard(3)}
              {renderSmallBoard(4)}
              {renderSmallBoard(5)}
            </div>
            <div className="ult-board-row low-row">
              {renderSmallBoard(6)}
              {renderSmallBoard(7)}
              {renderSmallBoard(8)}
            </div>
          </div>
        </div>
        {isDisplayBoard ? displayBoard : null}
      </div>
    </div>
  )
}

export default UltTicTac