import React, {useState} from 'react'
// import Switch from 'react-switch'
import { LuMoreVertical, LuMoreHorizontal }  from 'react-icons/lu'
import { IoIosSquareOutline, IoIosSquare } from 'react-icons/io'
import { GoSun, GoMoon } from 'react-icons/go'


function RulesPanel ({darkMode, toggleDarkMode, stack, toggleStack, displayBoard, toggleDisplayBoard, winningBoardsPlayable, toggleWinningBoardsPlayable}) {
    const [opened, setOpened] = useState(false)
    const toggleOpened = () => {
        setOpened(opened => !opened)
    }
    let titleStr = "Rules and Modifiers"
    if(opened) {
        titleStr = "△ " + titleStr + " △"
    } else {
        titleStr = '▽ ' + titleStr + ' ▽'

    }
    return (
        <div className='rules-panel'>
            <div 
                onClick={toggleOpened}
                className='rules-button'
            >
                {titleStr}
            </div>
            {opened ? 
            <>
            <div className='rule' onClick={toggleDarkMode}>
                <p>
                    {darkMode ? "Disable Dark Mode  " : "Enable Dark Mode  "}
                    {darkMode ? <GoMoon /> :<GoSun />}
                </p>
            </div>
            <div className='rule' onClick={toggleDisplayBoard}>
                <p>
                    {displayBoard ? 'Hide Display Board  ' : 'Show Display Board  '}
                    {displayBoard ? <IoIosSquare /> : <IoIosSquareOutline />}
                </p>
            </div>

            <div className={'rule' + (displayBoard ? "" : " disabled")} onClick={(displayBoard ? toggleStack : null)}>
                <p>
                    {stack ? 'Un-Stack Display Board  ' :'Stack Display Board  ' }
                    {stack ? <LuMoreVertical/>: <LuMoreHorizontal/>}
                </p>
            </div>
            <div className='rule' onClick={toggleWinningBoardsPlayable}>
                <p>
                    {winningBoardsPlayable ? 'Won boards are playable  ' : 'Won boards are un-playable  '}
                    {winningBoardsPlayable ? <IoIosSquare /> : <IoIosSquareOutline />}
                </p>
            </div>
            <div>
                <b>The Rules:</b>
                <p>Click on the mini board you want to play on; your selection will determine which mini board your opponent will be "sent" to play on next. If a mini board is full{winningBoardsPlayable ? "" : ", or has already been won"}, then play continues on any other mini board.<br></br>Winning a mini board gives you a permenant play on the major board. Winning 3 mini boards in a row wins the game.</p>
            </div>
            </>
            : null}
        </div>
    )
}

export default RulesPanel
