import React, { Component } from 'react';
import Board from "./Board.js";
//import ImageBox from "../components/ImageBox.js";
// const images = require.context('./chessimgs', true); unused



class Game extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div>
                <Board></Board>
            </div>
        )
    }
}
export class chess extends Component {
    render() {
        return (
            <div className="App main">
                <header>
                    <title>Chess</title>
                </header>
                    <Game />
                {/* <ImageBox
                    bgSrc="images/white.jpg" 
                    src="images/bp.png" 
                    alt="headshot" 
                    class="infoBox" 
                    text=
                    "Thanks for stopping by, I hope you make good use of the application."/> */}
                <footer className="App-footer">
                <a href='https://www.freepik.com/vectors/business'>Business vector created by macrovector - www.freepik.com</a>
                </footer>
            </div>
        )
    }
}

export default chess

//Unused Code

// OLD castle code
// if (whitesTurn && !kingMoved[1]) {// if white king hasnt moved before
//     if (!this.state.rooksMoved[2] && squares[2][7] === "n" && squares[1][7] === "n") { //white rook left
//         moves.push([1, 7]);
//     }
//     if (!this.state.rooksMoved[3] && squares[4][7] === "n" && squares[5][7] === "n" && squares[6][7] === "n") { //white rook right
//         moves.push([5, 7]);
//     }
// }
// if (!whitesTurn && !kingMoved[0]) {// if black king hasnt moved before
//     if (!this.state.rooksMoved[0] && squares[1][0] === "n" && squares[2][0] === "n" && squares[3][0] === "n") { //black rook left
//         moves.push([2, 0]);
//     }
//     if (!this.state.rooksMoved[1] && squares[5][0] === "n" && squares[6][0] === "n") { //black rook right
//         moves.push([6, 0]);
//     }
// }
// Tic Tac Toe code
// <div>
//     <div className="board-row">
//         {this.renderSquare(0)}
//         {this.renderSquare(1)}
//         {this.renderSquare(2)}
//     </div>
//     <div className="board-row">
//         {this.renderSquare(3)}
//         {this.renderSquare(4)}
//         {this.renderSquare(5)}
//     </div>
//     <div className="board-row">
//         {this.renderSquare(6)}
//         {this.renderSquare(7)}
//         {this.renderSquare(8)}
//     </div>
// </div>
// const boardStart ={board:[
//     ["Rook", "Knight", "Bishop", "Queen", "King", "Bisop", "Knight", "Rook"],
//     ["Pawn", "Pawn", "Pawn", "Pawn", "Pawn", "Pawn", "Pawn", "Pawn"],
//     [" ", " ", " ", " ", " ", " ", " ", " "],
//     [" ", " ", " ", " ", " ", " ", " ", " "],
//     [" ", " ", " ", " ", " ", " ", " ", " "],
//     [" ", " ", " ", " ", " ", " ", " ", " "],
//     ["Pawn", "Pawn", "Pawn", "Pawn", "Pawn", "Pawn", "Pawn", "Pawn"],
//     ["Rook", "Knight", "Bishop", "King", "Queen", "Bisop", "Knight", "Rook"]
// ]};